<template>
    <div>
        <div class="cart-list">
            <go-back-btn></go-back-btn>
            <div class="cart-tit">
                <div class="good-count">
                    共{{cartList.length}}类 {{allCount}}件商品
                </div>
                <div v-if="invalidList.length > 0" class="all-clear-invalid-btn" @click="invalidAll">
                    清空失效商品
                </div>
                <div  v-if="allList.length > 0" class="cart-management" @click="handleClickManagement">
                    <span v-if="bottomType">管理</span>
                    <span v-else>完成</span>
                </div>
            </div>
            <!-- 如果为空显示这里 -->
            <div v-if="isNull">
                <cart-null></cart-null>
            </div>
            <!-- 如果有数据显示这里 -->
            <div v-else>
                <div v-for="item in cartList" :key="item.shoppingcar_id">
                    <div class="cart-item-box" v-if="item.goods_type == '0' || item.goods_type == '1'">
                        <div class="cart-item">
                            <div class="cart-radio" style="margin-top:1.5rem;">
                                <van-checkbox :disabled="item.invalid ===1 && bottomType?true:false"  @click="(val)=>handleChangeSelect(!item.select, item.skuId)" checked-color="#ED6A0C" v-model="item.select" icon-size="1rem"></van-checkbox>
                            </div>
                            <div class="cart-img"
                                @click="handleChangeSelect(!item.select, item.skuId)">
                                <van-image fit="contain"
                                    class="img-box"
                                    :src="getImgSizeJpg(item.img,4.2,3.8)">
                                    <template v-slot:error>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                    <template v-slot:loading>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                </van-image>
                            </div>
                            <div class="cart-info">
                                <div class="cart-name">
                                    {{item.name}}
                                </div>
                                <span class="cart-sku" v-show="item.standardstr && item.invalid !== 1" @click="handleClickAddCart(item.spuId,item)">
                                    {{item.standardstr}}
                                    <van-icon name="arrow-down" />
                                </span>
                                <div class="cart-price-box">
                                    <div class="cart-price">￥<span style="font-weight:bold;">{{Number(item.price).toFixed(2)}}</span>/<span class="cart-price-unit">{{item.unit}}</span>
                                    </div>
                                    <div class="cart-count-box">
                                        <div v-if="item.invalid === 1" class="choose-again-btn" @click="handleClickchooseAgain(item.spuId,item)">重选</div>
                                        <van-stepper
                                            v-else
                                            button-size="1.2rem"
                                            async-change
                                            :min="item.min_order_quantity"
                                            :max="item.stock_num"
                                            @change="(val)=>handleChangeCount(val,item,item)" 
                                            :name="item.id" 
                                            :value="item.total_quantity" 
                                            integer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-item-box" v-if="item.goods_type == '2'">
                        <div class="cart-item">
                            <div class="cart-radio"  style="margin-top:1.5rem;">
                                <van-checkbox :disabled="item.invalid ===1&& bottomType?true:false"  @click="(val)=>handleChangeSelect(!item.select, item.skuId)" checked-color="#ED6A0C" v-model="item.select" icon-size="1rem"></van-checkbox>
                            </div>
                            <div class="cart-img"
                                @click="handleChangeSelect(!item.select, item.skuId)">
                                <van-image fit="contain"
                                    class="img-box"
                                    :src="getImgSizeJpg(item.img,4.2,3.8)">
                                    <template v-slot:error>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                    <template v-slot:loading>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                </van-image>
                            </div>
                            <div class="cart-info">
                                <div class="cart-name">
                                    {{item.name}}
                                </div>
                                <span class="cart-sku" v-show="item.standardstr && item.invalid !== 1" @click="handleClickAddCart(item.spuId,item)">
                                    {{item.standardstr}}
                                    <van-icon name="arrow-down" />
                                </span>
                            </div>
                            <div  v-if="item.invalid === 1" class="choose-again-btn absolute" @click="handleClickchooseAgain(item.spuId,item)">重选</div>
                        </div>
                        <div class="cart-sku-list" v-if="item.glass_myopia_atrr.attr_val.length > 0">
                            <div class="cart-sku-item" v-for="childItem in item.glass_myopia_atrr.attr_val" :key="childItem.id">
                                <div class="cart-sku-item-left">
                                    <div class="cart-sku-item-name">[{{childItem.myopia}},{{childItem.astigmatism}}]</div>
                                    <div class="cart-price"><span style="font-weight:bold;">{{Number(childItem.price).toFixed(2)}}</span><span class="cart-price-unit">/{{item.unit}}</span>
                                    </div>
                                </div>
                                <div class="cart-count-box">
                                    <div v-if="childItem.invalid === 1" class="choose-again-btn" @click="handleClickchooseAgain(item.spuId,item,childItem)">重选</div>
                                    <van-stepper
                                        v-else
                                        button-size="1.2rem" 
                                        min="0"
                                       :max="childItem.inventory"
                                        async-change
                                        @change="(val)=>handleChangeCount(val,childItem,item)" 
                                        :name="childItem.id" 
                                        :value="childItem.total_quantity" 
                                        integer />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-item-box" v-if="item.goods_type == '3'">
                        <div class="cart-item">
                            <div class="cart-radio"  style="margin-top:1.5rem;">
                                <van-checkbox :disabled="item.invalid ===1&& bottomType?true:false"  @click="(val)=>handleChangeSelect(!item.select, item.skuId)" checked-color="#ED6A0C" v-model="item.select" icon-size="1rem"></van-checkbox>
                            </div>
                            <div class="cart-img"
                                @click="handleChangeSelect(!item.select, item.skuId)">
                                <van-image fit="contain"
                                    class="img-box"
                                    :src="getImgSizeJpg(item.img,4.2,3.8)">
                                    <template v-slot:error>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                    <template v-slot:loading>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                </van-image>
                            </div>
                            <div class="cart-info">
                                <div class="cart-name">
                                    {{item.name}}
                                </div>
                                <span class="cart-sku" v-show="item.standardstr && item.invalid !== 1" @click="handleClickAddCart(item.spuId,item)">
                                    {{item.standardstr}}
                                    <van-icon name="arrow-down" />
                                </span>
                            </div>
                            <div  v-if="item.invalid === 1" class="choose-again-btn absolute" @click="handleClickchooseAgain(item.spuId,item)">重选</div>
                        </div>
                        <div class="cart-sku-list" v-if="item.glass_myopia_atrr.attr_val.length > 0">
                            <div class="cart-sku-item" v-for="(childItem,childIndex) in item.glass_myopia_atrr.attr_val" :key="childItem.id + childIndex">
                                <div class="cart-sku-item-left">
                                    <!-- <div class="cart-sku-item-name">[{{childItem.myopia}},{{childItem.astigmatism}}]</div> -->
                                    <div class="cart-sku-item-name">
                                         <span v-if="childItem.left_or_right_eyes !== '0'">{{ childItem.left_or_right_eyes | eyesTypeFilter }},</span>
                                        [{{childItem.myopia}},{{childItem.astigmatism}}]
                                        <span v-if="childItem.cylinder_axis">,散光轴位:{{childItem.cylinder_axis}}</span>
                                        <span v-if="childItem.pd">,瞳距(mm):{{childItem.pd}}</span>
                                        <span v-if="childItem.add_light">,下加光ADD:{{childItem.add_light}}</span>
                                    </div>
                                    <div class="cart-price"><span style="font-weight:bold;">{{Number(childItem.price).toFixed(2)}}</span><span class="cart-price-unit">/{{item.unit}}</span>
                                    </div>
                                </div>
                                <div class="cart-count-box">
                                    <div v-if="childItem.invalid === 1" class="choose-again-btn" @click="handleClickchooseAgain(item.spuId,item,childItem)">重选</div>
                                    <van-stepper
                                        v-else
                                        button-size="1.2rem" 
                                        min="0"
                                        max="999999"
                                        async-change
                                        @change="(val)=>handleChangeCount(val,childItem,item)" 
                                        :name="childItem.id" 
                                        :value="childItem.total_quantity" 
                                        integer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="(item,index) in invalidList" :key="index">
                    <div class="cart-item-box"  v-if="item.goods_type == '0' || item.goods_type == '1'">
                        <div class="cart-item">
                            <div class="invalid-tip">
                                失效
                            </div>
                            <div class="cart-img"
                                @click="handleChangeSelect(!item.select, item.skuId)">
                                <van-image fit="contain"
                                    class="img-box"
                                    :src="getImgSizeJpg(item.img,4.2,3.8)">
                                    <template v-slot:error>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                    <template v-slot:loading>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                </van-image>
                            </div>
                            <div class="cart-info">
                                <div class="cart-name">
                                    {{item.name}}
                                </div>
                                <div class="invalid-con">
                                    {{item.reason}}
                                </div>
                                <div class="clear-invalid-btn" @click="invalidOne(item.shoppingcar_id)">
                                    清除
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-item-box"  v-if="item.goods_type == '2'">
                        <div class="cart-item">
                            <div class="invalid-tip">
                                失效
                            </div>
                            <div class="cart-img"
                                @click="handleChangeSelect(!item.select, item.skuId)">
                                <van-image fit="contain"
                                    class="img-box"
                                    :src="getImgSizeJpg(item.img,4.2,3.8)">
                                    <template v-slot:error>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                    <template v-slot:loading>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                </van-image>
                            </div>
                            <div class="cart-info">
                                <div class="cart-name">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
                        <div class="cart-sku-list">
                            <div class="cart-sku-item" v-for="childItem in item.glass_myopia_atrr.attr_val" :key="childItem.id">
                                <div class="cart-sku-item-left">
                                    <div class="cart-sku-item-name">[{{childItem.myopia}},{{childItem.astigmatism}}]</div>
                                    <div class="cart-sku-invalid-con">
                                        {{childItem.reason}}
                                    </div>
                                </div>
                                <div class="cart-sku-clear-invalid-btn" @click="invalidOne(childItem.shoppingcar_id)">
                                    清除
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-item-box"  v-if="item.goods_type == '3'">
                        <div class="cart-item">
                            <div class="invalid-tip">
                                失效
                            </div>
                            <div class="cart-img"
                                @click="handleChangeSelect(!item.select, item.skuId)">
                                <van-image fit="contain"
                                    class="img-box"
                                    :src="getImgSizeJpg(item.img,4.2,3.8)">
                                    <template v-slot:error>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                    <template v-slot:loading>
                                        <img style="width:100%;height:3.8rem" :src="defaultImg" />
                                    </template>
                                </van-image>
                            </div>
                            <div class="cart-info">
                                <div class="cart-name">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
                        <div class="cart-sku-list">
                            <div class="cart-sku-item" v-for="childItem in item.glass_myopia_atrr.attr_val" :key="childItem.id">
                                <div class="cart-sku-item-left">
                                    <div class="cart-sku-item-name">[{{childItem.myopia}},{{childItem.astigmatism}}]</div>
                                    <div class="cart-sku-invalid-con">
                                        {{childItem.reason}}
                                    </div>
                                </div>
                                <div class="cart-sku-clear-invalid-btn" @click="invalidOne(childItem.shoppingcar_id)">
                                    清除
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isNull">
            <div class="cart-bottom">
                <div class="cart-bottom-radio">
                    <van-checkbox v-model="allSelect" @click="handleClickAllSelect(allSelect)" checked-color="#ED6A0C" icon-size="1rem">全选</van-checkbox>
                </div>
                <div class="cart-bottom-right">
                    <div class="cart-bottom-price" v-if="bottomType">
                        合计：<span class="cart-total-price">￥{{Number(allPrice).toFixed(2)}}</span>
                    </div>
                    <div class="cart-bottom-buy" v-if="bottomType" @click="handleClickGoOrderConfirm">
                        <div class="cart-bottom-buy-btn">结算({{selectCount}})</div>
                    </div>
                    <div class="cart-bottom-buy" v-if="!bottomType" @click="handleClickDelConfirm">
                        <div class="cart-bottom-del-btn">删除({{selectCount}})</div>
                    </div>
                </div>
            </div>
        </div>
         <div v-if="isNull" class="allBuy">
            <span class="dian themeBg"></span>
            大家都在订
            <span class="dian themeBg"></span>
        </div>
        <div v-if="isNull" style="margin-bottom:3rem">
            <good-list @getCartList="getCartList"></good-list>
        </div>
        <!-- sku弹窗 -->
        <sku-glass-lens ref="skuPopup" 
            :type="type" 
            :isChooseAgain="isChooseAgain"
            :cartId="cartId"
            :cartId2="cartId2"
            :currCartItem="currCartItem"
            :propShow="propShowGlass" 
            @invalidOne="invalidOne"
            @getCartList="getCartList" 
            @handleClickPropShow="handleClickPropShowGlass"></sku-glass-lens>
        <sku-custom-lens ref="skuPopupCustom" 
            :type="type" 
            :isChooseAgain="isChooseAgain"
            :cartId="cartId"
            :cartId2="cartId2"
            :currCartItem="currCartItem"
            :propShow="propShowCustomGlass" 
            @invalidOne="invalidOne"
            @getCartList="getCartList" 
            @handleClickPropShow="handleClickPropShowCustomGlass"></sku-custom-lens>
    </div>
</template>
<script>
import './index.less'
import goodList from "@/view/Public/goodList"
import goBackBtn from "@/view/Public/goBackBtn"
import cartNull from "../cartNull"
import skuGlassLens from "@/view/Public/skuGlassLens"
import skuCustomLens from "@/view/Public/skuCustomLens"
import { cartList ,computePromotion ,cartdele ,cartEdit,cartEdit2 } from "@/apis/api"
import defaultImg from "@/assets/image/default.png"
import bshop_global from "@/libs/global"
// 左眼 右眼
const eyesTypeMap = {
    '0': {
        value: '0',
        name: '不区分'
    },
    '1': {
        value: '1',
        name: '左眼'
    },
    '2': {
        value: '2',
        name: '右眼'
    }
}
export default {
    name:'cart-list',
    components:{ cartNull , goodList, skuGlassLens, skuCustomLens, goBackBtn },
    data(){
        return{
            // 下边的数据就是为了农科尚品 一个死的150元 以后 这个就可以删掉了
            sysOfficeId:localStorage.getItem('sysOfficeId'),
            nongSysOfficeId:bshop_global.sysOfficeId, // 农科尚品经销商id
            // 上边的数据就是为了农科尚品 一个死的150元 以后 这个就可以删掉了
            defaultImg:defaultImg,
            allList:[],
            allSelect:false,
            isNull:true,
            selectIdArr:[],//删除是选中的数组id
            selectCount:0, // 选中的数量
            allCount:0,//全部数量
            cartList:[], //购物车列表
            invalidList:[], //失效列表
            allPrice:0, //总价
            isNull:false,
            bottomType:true,//底部是删除还是结算
            // 传到sku组件的数据 start
            type:"edit", //弹窗类型 是编辑还是加购
            cartId:"", //要编辑的购物车id
            cartId2:"",//要编辑的度数散光购物车id
            currCartItem:{}, //要编辑的购物车item 
            propShowGlass:false,
            propShowCustomGlass:false,
            // 传到sku组件的数据 end
            tempUpdateItem:{}, //进行编辑sku的项
            isChooseAgain:false,// 是否重选
            index:0
        }
    },
    watch:{
        //监听allList的数量，显示“这里什么也没有”
        allList(v){
            if(v.length == 0){
                this.isNull = true;
                // this.$emit("onCartList",0)
            }else{
                this.isNull = false;
                // this.$emit("onCartList",1)
            }
        },
        cartList: {
            handler(newList) {
                let selectIdArr = []
                newList.map(item=>{
                    if(item.goods_type == '2' || item.goods_type == '3'){
                        if(item.select){
                            item.glass_myopia_atrr.attr_val.map(childItem=>{
                                selectIdArr.push(childItem.shoppingcar_id)
                            })
                        }
                    }else{
                        if(item.select){
                            selectIdArr.push(item.shoppingcar_id)
                        }
                    }
                })
                this.selectIdArr = selectIdArr
            },
        }
    },
    mounted(){
        this.getCartList();
    },
    filters: {
        eyesTypeFilter (state) {
            return eyesTypeMap[state].name
        }
    },
    methods:{
        //获取购物车列表
        getCartList(){
            cartList().then(res=>{
                if(res.code == 0){
                    let skulist = res.res.skulist;
                    let invalid = res.res.invalid;
                    skulist.map(item=>{
                        item.select = false;
                        //所有商品数量
                    })
                    this.allSelect = 0
                    this.selectCount = 0
                    this.cartList = skulist;
                    this.invalidList = invalid;
                    this.allList = this.cartList.concat(this.invalidList)
                    this.updateAllCount();
                }
            })
        },
        //修改所有商品数量
        updateAllCount(){
            let allCount = 0;
            let data = this.cartList;
            data.map(item=>{
                if(item.goods_type == '2' || item.goods_type == '3'){
                    item.glass_myopia_atrr.attr_val.map(childItem=>{
                        allCount = allCount +  childItem.total_quantity        
                    })
                }else{
                    allCount = allCount +  item.total_quantity        
                }
            })
            this.allCount = allCount
        },
        //清除全部失效
        invalidAll(){
            let invalid = this.invalidList;
            let shoppingId = []
            invalid.map(item=>{
                if(item.goods_type == '2'|| item.goods_type == '3'){
                    item.glass_myopia_atrr.attr_val.map(childItem=>{
                        shoppingId.push(childItem.shoppingcar_id)
                    })
                }else{
                    shoppingId.push(item.shoppingcar_id)
                }
            })
            let data = {
                shoppingId:shoppingId
            }
            cartdele(data).then(res=>{
                if(res.code == 0){
                        this.$toast("清除成功")
                        this.getCartList()
                }else{
                        this.$toast(res.msg)
                }
            })
        },
        //清除单个失效
        invalidOne(id){
            if(!Array.isArray(id)){
                id = [id]
            }
            let data = {
                shoppingId:id
            }
            cartdele(data).then(res=>{
                if(res.code == 0){
                    // this.$toast("清除成功")
                    this.getCartList()
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        //购物车价钱计算
        getComputePromotion(list){
            let cartList= list;
            let skulist = [];
            let skulistItem = {};
            cartList.map(item=>{
                if(item.goods_type == '2' || item.goods_type == '3'){
                    //如果是镜片商品
                    item.glass_myopia_atrr.attr_val.map(childItem=>{
                        if(childItem.invalid !== 1){
                            skulistItem = {
                                spuId:item.spuId,
                                skuId:item.skuId,
                                sku_measure_id:item.sku_measure_id,
                                quantity:childItem.total_quantity,
                                myopia:childItem.myopia+"|"+childItem.astigmatism
                            }
                            skulist.push(skulistItem)
                        }
                    })
                }else{
                    if(item.invalid !== 1){
                        skulistItem = {
                            spuId:item.spuId,
                            skuId:item.skuId,
                            sku_measure_id:item.sku_measure_id,
                            quantity:item.total_quantity
                        }
                        skulist.push(skulistItem)
                    }
                }
            })
            let data = {skulist:skulist};
            computePromotion(data).then(res=>{
                if(res.code == 0){
                    this.allPrice = res.res.total_money;
                }
            })
        },
        //点击选择单个商品
        handleChangeSelect(v,id){
            let cartList = [...this.cartList]
            let selectCount = 0;
            cartList.forEach(item=>{
                if(item.skuId === id){
                    if(this.bottomType){
                        if(item.invalid !== 1){
                            item.select = v
                        }
                    }else{
                        item.select = v
                    }
                }
                if(item.select){
                    selectCount = selectCount + 1
                }
            })
            this.selectCount = selectCount
            this.cartList = cartList;
            if(this.selectCount >= this.cartList.length){
                //修改子组件的全选
                this.allSelect = true
            }else{
                //修改子组件的全选
                this.allSelect = false
            }
            this.handleClickAllPrice()
        },
        //点击全选
        handleClickAllSelect(v){
            this.allSelect = !this.allSelect;
            let cartList = [...this.cartList]
            let selectCount = 0;
            cartList.forEach(item=>{
                if(this.bottomType){
                    if(item.invalid !== 1){
                        item.select = this.allSelect;
                    }  
                }else{
                    item.select = this.allSelect;
                }
                if(item.select){
                    selectCount = selectCount + 1
                }
            })
            this.selectCount = selectCount;
            this.cartList = cartList;
            this.handleClickAllPrice()
        },
        //点击管理
        handleClickManagement(){
            this.bottomType = !this.bottomType;
            //点击管理和完成的时候  需要判断是否有需要重选的商品
            //点击完成  需要将重选的商品select改为false
            let cartList = [...this.cartList]
            let selectCount = 0;
            if(this.bottomType){
                //底部是结算
                cartList.map(item=>{
                    if(item.invalid === 1){
                        item.select = false
                    } 
                    if(item.select){
                        selectCount = selectCount + 1
                    }
                })
            }else{
                //底部是删除
                if(this.allSelect){
                    //如果全选状态
                    cartList.map(item=>{
                        if(item.invalid === 1){
                            item.select = true
                        } 
                        if(item.select){
                            selectCount = selectCount + 1
                        }
                    })
                }else{
                    selectCount = this.selectCount
                }
            }
            this.selectCount = selectCount
            this.cartList = cartList
        },
        //步进器修改个数
        handleChangeCount(v,childItem,item){
            //防止异步修改时再次调用 start
            this.index ++;
            if(this.index%2 == 0){
                return false
            }
            //防止异步修改时再次调用 end
            let cartList = [...this.cartList]
            if(item.goods_type == '0' || item.goods_type == '1'){
                if( v < 1){
                    this.$toast("不能再少了")
                    return false
                }
            }
            let count = Number(v);
            let selectAtrrs = [];
            if(item.attrs){ //如果是单规格的是没有这个属性的
               item.attrs.map(item=>{
                    let atrrsItem = { 
                        arr_id: item.attr_id,
                        val: item.attr_val_id,
                        type: item.type
                    }
                    selectAtrrs.push(atrrsItem)
                })
            }
            let skulist = {
                spuId:item.spuId,
                skuId:item.skuId,
                sku_measure_id:item.sku_measure_id,
                quantity:count,
                shoppingId:item.shoppingcar_id,
                attrs:selectAtrrs
            }
            if(item.goods_type == '2'){
                skulist.myopia = childItem.id;
                skulist.shoppingId = childItem.shoppingcar_id;
            }
            if(item.goods_type == '3'){
                skulist.myopia = childItem.id;
                skulist.shoppingId = childItem.shoppingcar_id;
                skulist.addlight = childItem.add_light;
                skulist.leftOrRightEyes = childItem.left_or_right_eyes;
                skulist.cylinderaxis = childItem.cylinder_axis;
                skulist.pd = childItem.pd
            }
            this.$toast.loading({
                forbidClick: true
            })
            let data = {};
            if(item.goods_type != '3'){
                data = skulist
                cartEdit(data).then(res=>{
                    if(res.code == 0){
                        this.$toast.clear();
                        this.show = !this.show
                        let updateData = this.cartList;
                        updateData.map(item=>{
                            if(item.goods_type == '2'){
                                item.glass_myopia_atrr.attr_val.map(childItem=>{
                                    if(childItem.shoppingcar_id == data.shoppingId){
                                        childItem.total_quantity =  count 
                                        if(childItem.total_quantity == 0){
                                            this.index = 0
                                            this.removeItem(item,childItem)
                                        }
                                        this.updateAllCount();
                                        this.handleClickAllPrice();
                                    }
                                })
                            }else{
                                if(item.shoppingcar_id == data.shoppingId){
                                    item.total_quantity =  count;
                                    this.updateAllCount();
                                    this.handleClickAllPrice();
                                }
                            }
                            
                        })
                    }else{
                        this.getCartList()
                        this.index = 0
                        this.$toast(res.msg)
                    }
                })
            }
            else{
                data = {
                    skulist:[skulist]
                }
                cartEdit2(data).then(res=>{
                    if(res.code == 0){
                        this.$toast.clear();
                        this.show = !this.show
                        let updateData = this.cartList;
                        updateData.map(item=>{
                            item.glass_myopia_atrr.attr_val.map(childItem=>{
                                if(childItem.shoppingcar_id == skulist.shoppingId){
                                    childItem.total_quantity =  count 
                                    if(childItem.total_quantity == 0){
                                        this.index = 0
                                        this.removeItem(item,childItem)
                                    }
                                    this.updateAllCount();
                                    this.handleClickAllPrice();
                                }
                            })
                        })
                    }else{
                        this.index = 0
                        this.$toast(res.msg)
                    }
                })
            }
        },
        //镜片品减到0 的话 那就移除这一项 如果该项下所有镜片规格没有 就清除这个购物车信息
        removeItem(cartItem,cartChildItem){
            let tempList = this.cartList;
            tempList.map((item,index)=>{
                if(item.goods_type == "2" || item.goods_type == "3"){  //因为只有镜片品可以减到0 
                    item.glass_myopia_atrr.attr_val.map((childItem,childIndex)=>{
                        if(childItem.shoppingcar_id == cartChildItem.shoppingcar_id){
                            item.glass_myopia_atrr.attr_val.splice(childIndex--,1)
                        }
                    })
                    if(item.glass_myopia_atrr.attr_val.length == 0){
                        tempList.splice(index--,1)
                        this.allCount--;
                        if(this.allCount == 0){
                            this.isNull = true
                        }
                        //修改购物车图标组件中的个数
                        this.$store.dispatch('setCartCount')
                    }
                }
            })
        },
        //计算总价
        handleClickAllPrice(){
            let cartList = [...this.cartList]
            let list = [];
            cartList.forEach(item=>{
                //如果全部选中 计算选中所有的价钱 放到总价中
                if(item.select){
                    // allPrice = allPrice + Number(item.price) * Number(item.count)
                    list.push(item)
                }
            })
            this.getComputePromotion(list)
        },
        //重选
        handleClickchooseAgain(id,item,childItem){
            this.type = "add"
            this.isChooseAgain = true
            this.cartId = item.shoppingcar_id
            if(childItem){
                this.cartId2 = childItem.shoppingcar_id
            }
            this.currCartItem = item //镜片品需要获得散光光度列表中是否已有信息  如果没有 就要add 否则就要edit

            if(item.goods_type == '3'){
                this.propShowCustomGlass = !this.propShowCustomGlass
                this.$refs.skuPopupCustom.goodsDetail(id,{});
            }else{
                this.propShowGlass = !this.propShowGlass;
                this.$refs.skuPopup.goodsDetail(id,{});
            }
        },
        //点击sku 显示sku弹窗 设置默认值
        handleClickAddCart(id,item){
            this.isChooseAgain = false
            this.cartId = item.shoppingcar_id
            this.currCartItem = item //镜片品需要获得散光光度列表中是否已有信息  如果没有 就要add 否则就要edit
            // if(item.goods_type == '3'){
            //     this.propShowCustomGlass = !this.propShowCustomGlass
            // }else{
            //     this.propShowGlass = !this.propShowGlass;
            // }
            
            //已选择的sku数据  sku初始数据
            let initialSkuValue = {};
            initialSkuValue['selectedNum'] = item.total_quantity
            item.attrs.map(childItem=>{
                initialSkuValue[childItem.attr_id] = childItem.attr_val_id
            })
            //点击触发子组件获取详情的方法  第二个参数是初始化sku数据
            if(item.goods_type == '3'){
                this.propShowCustomGlass = !this.propShowCustomGlass
                this.$refs.skuPopupCustom.goodsDetail(id,initialSkuValue);
            }else{
                this.propShowGlass = !this.propShowGlass;
                this.$refs.skuPopup.goodsDetail(id,initialSkuValue);
            }
            //修改sku时 将新的数据更新在列表中
            // this.tempUpdateItem = item
        },
        //去确认订单页面
        handleClickGoOrderConfirm(){
            if(this.selectCount === 0){
                this.$toast("请选择商品")
            }else{
                // 如果是农科商品 要满足1500的价格
                if((this.sysOfficeId === this.nongSysOfficeId) && (this.allPrice < 1500)){
                    this.$toast("商品总价低于1500,再去看看其他的吧！")
                    return false
                }
                let selectItemArr = []
                let carLists = [...this.cartList]
                carLists.map(item=>{
                    if(item.select){
                        if(item.goods_type == '2' || item.goods_type == '3'){
                            //如果是镜片商品
                            for (var i = 0; i < item.glass_myopia_atrr.attr_val.length; i++) {
                            　　if (item.glass_myopia_atrr.attr_val[i].invalid === 1) {
                                    item.glass_myopia_atrr.attr_val.splice(i, 1); // 将使后面的元素依次前移，数组长度减1
                            　　　　  i--; // 如果不减，将漏掉一个元素
                            　　}
                            }
                            selectItemArr.push(item)
                        }else{
                            if(item.invalid !== 1){ // 判断是否失效
                                selectItemArr.push(item)
                            }
                        }
                    }
                })
                localStorage.setItem('selectItemArr',JSON.stringify(selectItemArr))
                this.$router.push({ 
                    name:'order-confirm'
                })
            }
        },
        //删除购物车
        handleClickDelConfirm(){
            if(this.selectCount === 0){
                this.$toast("请选择商品")
            }else{
                this.$dialog.confirm({
                    width:'88%',
                    title: '提示',
                    message: '确认删除吗？'
                }).then(() => {
                    let data = {
                        shoppingId:this.selectIdArr
                    }
                    cartdele(data).then(res=>{
                        if(res.code == 0){
                             this.$toast("删除成功")
                             this.getCartList();
                             this.selectCount = 0;
                             this.bottomType = true;
                             this.allSelect = false;
                             //修改购物车图标组件中的个数
                             this.$store.dispatch('setCartCount')
                        }else{
                             this.$toast(res.msg)
                        }
                    })
                }).catch(() => {
                    this.$toast("取消成功")
                });
            }
        },
        //切换sku 组件的propShow  start
        handleClickPropShowGlass(v){
            this.propShowGlass = v
        },
        handleClickPropShowCustomGlass(v){
            this.propShowCustomGlass = v
        }
        //切换sku 组件的propShow  end
    }
}
</script>