<template>
    <div class="gray-bg cart" v-htmltit data-title="购物车">
        <go-home-btn></go-home-btn>
        <skeleton v-if="!loading"></skeleton>
        <template v-if="loading">
            <div  :style="isNull?'':'margin-bottom:3rem;'">
                <cart-list @onCartList="onCartList"></cart-list>
            </div>
        </template>
    </div>
</template>
<script>
import skeleton from "./component/skeleton"
import goHomeBtn from "@/view/Public/goHomeBtn"
import cartList from "./component/cartList"
export default {
    name:"cart",
    components:{ skeleton ,goHomeBtn, cartList},
    data(){
        return{
            loading:true, //加载成功之前显示骨架页
            isNull:false
        }
    },
    mounted(){
        setTimeout(()=>{
            this.loading = true
        },1000)
    },
    methods:{
        //监听有没有cartList  如果为空显示下边的大家都再订
        onCartList(v){
            if(!v){
                this.isNull = true
            }else{
                this.isNull = false
            }
        }
    }
}
</script>