<template>
    <div>
        <div class="skeleton-head">
            <div class="skeleton-head-type">
                <van-skeleton :row="1" row-width="2.5rem" />
                <van-skeleton :row="1" row-width="2rem" />
            </div>
        </div>
        <div class="skeleton-list">
            <div class="skeleton-item" v-for="(item,index) in data" :key="index">
                <div style="margin-top:1.5rem;">
                    <van-skeleton avatar avatar-size="1rem"/>
                </div>
                <div style="width:30%;margin-right:3%">
                    <van-skeleton title title-width="100%" />
                    <van-skeleton title title-width="100%" />
                    <van-skeleton title title-width="100%" />
                    <van-skeleton title title-width="100%" />
                </div>
                <div style="width:60%">
                    <van-skeleton class="width100" :row="1" row-width="100%" />
                    <van-skeleton class="width100" :row="1" row-width="100%" />
                    <van-skeleton class="price" style="margin-top:1rem;" :row="1" row-width="50%"/>
                    <div class="skeleton-item-opt">
                        <van-skeleton title title-width="5rem" />
                        <van-skeleton title title-width="4rem" />
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "./index.less"
export default {
    name:"skeleton",
    data(){
        return{
            data:[0,1,2,3,4]
        }
    }
}
</script>