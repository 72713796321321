<style scoped>
    .cart-null{
        font-size: 0.8rem;color: #969799;text-align: center;padding: 3rem 0;
    }
</style>
<template>
    <div class="cart-null">
        <van-image
            width="7.2rem"
            height="5.8rem"
            :src="cartNull">
            <template v-slot:loading>
                <van-loading type="spinner" size="20" />
            </template>    
        </van-image>
        <div style="margin-top:0.5rem;">购物车里空空如也~</div>
    </div>
</template>
<script>
import cartNull from '@/assets/image/cartNull.png'
export default {
    name:"cart-null",
    data(){
        return{
            cartNull:cartNull
        }
    }
}
</script>